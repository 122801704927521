export const beforeAndAfterCarouselInit = () => {
  const carousels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.c-ba-carousel');

  if (!carousels.length) {
    return;
  }

  carousels.forEach((carousel) => {
    const indicatorsLabels: NodeListOf<HTMLSpanElement> = carousel.querySelectorAll('.aaaem-carousel__indicator > span');

    indicatorsLabels.forEach((indicatorLabel) => {
      const span: HTMLSpanElement = document.createElement('span');
      span.innerText = indicatorLabel.innerText;
      indicatorLabel.parentElement!.insertBefore(span, indicatorLabel);
    });
  });  
}