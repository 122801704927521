const _temp0 = require("../beforeAndAfterCarousel/_beforeAndAfterCarousel.scss");

const _temp1 = require("../beforeAndAfterShort/_beforeAndAfterShort.scss");

const _temp2 = require("../brandStatements/_brandStatements.scss");

const _temp3 = require("../bulletPoints/_bulletPoints.scss");

const _temp4 = require("../button/_button.scss");

const _temp5 = require("../comparisonSlider/_comparisonSlider.scss");

const _temp6 = require("../faqShort/_faqShort.scss");

const _temp7 = require("../findACllinicHero/_findAClinicHero.scss");

const _temp8 = require("../findClinicForm/_findClinicForm.scss");

const _temp9 = require("../footNotes/_footNotes.scss");

const _temp10 = require("../footer/_footer.scss");

const _temp11 = require("../footerInfo/_footerInfo.scss");

const _temp12 = require("../header/_header.scss");
const _temp13 = require("../header/_nav.scss");

const _temp14 = require("../iclDetail/_iclDetail.scss");

const _temp15 = require("../introText/_introText.scss");

const _temp16 = require("../keyTakeAways/_keyTakeAways.scss");

const _temp17 = require("../locationServices/_locationServices.scss");

const _temp18 = require("../mediatext/_mediatext.scss");

const _temp19 = require("../qaContainer/_qaContainer.scss");

const _temp20 = require("../scrollReveal/_scrollReveal.scss");

const _temp21 = require("../searchLocation/_searchLocation.scss");

const _temp22 = require("../teaser/_teaser.scss");

const _temp23 = require("../testimonialMedia/_testimonialMedia.scss");

const _temp24 = require("../topicNavigation/_topicNavigation.scss");

module.exports = {
  "beforeAndAfterCarousel":   {
    "_beforeAndAfterCarousel": _temp0
  },
  "beforeAndAfterShort":   {
    "_beforeAndAfterShort": _temp1
  },
  "brandStatements":   {
    "_brandStatements": _temp2
  },
  "bulletPoints":   {
    "_bulletPoints": _temp3
  },
  "button":   {
    "_button": _temp4
  },
  "comparisonSlider":   {
    "_comparisonSlider": _temp5
  },
  "faqShort":   {
    "_faqShort": _temp6
  },
  "findACllinicHero":   {
    "_findAClinicHero": _temp7
  },
  "findClinicForm":   {
    "_findClinicForm": _temp8
  },
  "footNotes":   {
    "_footNotes": _temp9
  },
  "footer":   {
    "_footer": _temp10
  },
  "footerInfo":   {
    "_footerInfo": _temp11
  },
  "header":   {
    "_header": _temp12,
    "_nav": _temp13
  },
  "iclDetail":   {
    "_iclDetail": _temp14
  },
  "introText":   {
    "_introText": _temp15
  },
  "keyTakeAways":   {
    "_keyTakeAways": _temp16
  },
  "locationServices":   {
    "_locationServices": _temp17
  },
  "mediatext":   {
    "_mediatext": _temp18
  },
  "qaContainer":   {
    "_qaContainer": _temp19
  },
  "scrollReveal":   {
    "_scrollReveal": _temp20
  },
  "searchLocation":   {
    "_searchLocation": _temp21
  },
  "teaser":   {
    "_teaser": _temp22
  },
  "testimonialMedia":   {
    "_testimonialMedia": _temp23
  },
  "topicNavigation":   {
    "_topicNavigation": _temp24
  }
}