const CONSTANTS = {
  brandName: 'HArmonyCa',
  allergan: 'allergan',
  fb: 'facebook',
  insta: 'instagram',
  comparisonSlider: {
    before: 'before',
    after: 'after',
    tracker: {},
  },
  eventNames: {
    headerNav: 'global_nav',
    footerNav: 'footer_nav',
    carousel: 'carousel',
    comparisonSliderMove: 'slide_change',
    treatmentFAQ: 'faq',
    videoPlay: 'video_play',
    videoPause: 'video_pause',
    videoEnd: 'video_complete',
    secondaryNav: 'secondary_nav',
  },
};

// pushes data to data layer for a given data object
const addToDL = data => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * returns NodeList of elements
 * @param sel - [string] - selector which needs to be looked for, in a given parent, or in document
 * @param parent - [HTMLElement|optional] - Element inside which the querySelector All should be looked for - fallbacks to document if not passed
 * @returns Node List of all the elements matching the selector inside the parent or document
 */
const qsAll = (sel: string, parent?): NodeList =>
  (parent || document)?.querySelectorAll(sel);

/**
 * returns HTMLElement
 * @param sel - [string] - selector which needs to be looked for, in a given parent, or in document
 * @param parent - [HTMLElement|optional] - Element inside which the querySelector All should be looked for - fallbacks to document if not passed
 * @returns first HTMLElement matching the selector inside the parent or document
 */
const qs = (sel: string, parent?): HTMLElement | null =>
  (parent || document)?.querySelector(sel);

/**
 * Waits for an element with the selector to present itself in a given parent or in document, and resolves with the element
 * @param selector - [string] - selector for which a
 * @param parent - [HTMLElement|optional] - Element inside which the querySelector All should be looked for - fallbacks to document if not passed
 * @returns Promise. Resolves with the element when found inside the parent or in the document
 */
const waitForElement = (selector, parent?) => {
  return new Promise(resolve => {
    if (qs(selector)) {
      return resolve(qs(selector));
    }
    const observer = new MutationObserver(() => {
      if (qs(selector)) {
        resolve(qs(selector));
        observer.disconnect();
      }
    });
    observer.observe((parent || document).body, {
      childList: true,
      subtree: true,
    });
  });
};

/**
 * Appends click event to Anchor Link and pushes the event to data layer
 * @param link - [HTMLAnchorElement] - anchor to which the listener is to be appended
 * @param eventName - [string] - DL Event name to be pushed when the anchor is clicked
 * @param text - [string|optional] - value of the element_text to be pushed to DL. Fallbacks to textContent of the link if not provided.
 */
const appendAnchorClickListener = (
  link: HTMLAnchorElement,
  eventName: string,
  text?
) => {
  const anchorText = text || link?.textContent;
  if (link && anchorText && eventName) {
    link.addEventListener('click', () => {
      addToDL({
        event: eventName,
        element_text: anchorText,
      });
    });
  }
};

const initDataLayerEvents = () => {
  // analytics based on the components' Bus events
  const addBusEvents = () => {
    if (window.Bus) {
      // for tracking events of carousel's navigation clicks in treatment page
      // the carousels should have an id that starts with 'treatment-page-carousel-' in order for this analytics tracking to be working
      window.Bus.on('emu-carousel:navClick', params => {
        if (params.id.indexOf('treatment-page-carousel-') >= 0) {
          const treatmentTitle = document
            .querySelector(`#${params.id}`)
            ?.closest('.c-ba-carousel')
            ?.querySelector('.emu-title .emu-title__text')
            ?.textContent?.trim();
          addToDL({
            event: CONSTANTS.eventNames.carousel,
            element_text: treatmentTitle || '',
            element_status: params.activeSlideTexts[0],
          });
        }
      });

      // for tracking comparison slider events in results page
      if (document.body.classList.contains('p-results')) {
        const sliders = document.querySelectorAll(
          '.c-beforeandaftershort__container .emu-comparison-slider__slider'
        );

        if (sliders?.length) {
          sliders.forEach(slider => {
            // fetching the title that is present in the container of the comparison slider
            const title = slider
              ?.closest('.c-beforeandaftershort')
              ?.querySelector(
                '.c-beforeandaftershort__title--mobile .cmp-title__text'
              )
              ?.textContent?.trim();
            // fetching id of the container. If ID isn't there assigning a random value for internal flag tracking purposes
            const id =
              slider?.closest('.emu-comparison-slider')?.id ||
              Math.floor(Math.random() * 999999999999999);

            // slide event present on the img-comparison-slider element
            slider.addEventListener('slide', () => {
              // @ts-ignore
              const pos = slider.exposure;
              let positionText = CONSTANTS.comparisonSlider.before;
              if (pos >= 50) {
                positionText = CONSTANTS.comparisonSlider.after;
              }

              // slide event gets called several times. Adding a tracker text to check for a change and then send the event to DL
              const curTrackerText =
                CONSTANTS.comparisonSlider.tracker[id] || '';
              if (curTrackerText === '' || curTrackerText !== positionText) {
                CONSTANTS.comparisonSlider.tracker[id] = positionText;

                // sending event to DL
                addToDL({
                  event: CONSTANTS.eventNames.comparisonSliderMove,
                  element_text: title || '',
                  element_status: positionText,
                });
              }
            });
          });
        }
      }

      // for tracking FAQ in treatment page
      if (document.body.classList.contains('p-treatment')) {
        window.Bus.on('emu-accordion:toggle', params => {
          if (params.itemClicked.title && params.itemClicked?.opened) {
            addToDL({
              event: CONSTANTS.eventNames.treatmentFAQ,
              element_text: params.itemClicked.title,
            });
          }
        });
      }

      // tracking play, pause and end for the videos in treatment and qa pages
      if (
        document.body.classList.contains('p-results') ||
        document.body.classList.contains('p-qa')
      ) {
        window.Bus.on('emu-video-embed:play', params => {
          addToDL({
            event: CONSTANTS.eventNames.videoPlay,
            element_text: params.title,
          });
        });

        window.Bus.on('emu-video-embed:pause', params => {
          addToDL({
            event: CONSTANTS.eventNames.videoPause,
            element_text: params.title,
          });
        });

        window.Bus.on('emu-video-embed:end', params => {
          addToDL({
            event: CONSTANTS.eventNames.videoEnd,
            element_text: params.title,
          });
        });
      }
    }
  };

  // adds analytics to the links in the header
  const addHeaderAnalytics = () => {
    const eventName = CONSTANTS.eventNames.headerNav;
    const header = qs('header.experiencefragment');

    if (header) {
      const navLogo = qsAll(
        '.header__logo .emu-image__link',
        header
      ) as NodeListOf<HTMLAnchorElement>;
      const navListAnchors = qsAll(
        '.navigationlist a',
        header
      ) as NodeListOf<HTMLAnchorElement>;
      const langNavTriggers = qsAll(
        '.cmp-languagenavigation__switcher',
        header
      ) as NodeListOf<HTMLAnchorElement>;
      const langDropdownAnchors = qsAll(
        '.emu-language-nav__group a',
        header
      ) as NodeListOf<HTMLAnchorElement>;
      const findClinicLinks = qsAll(
        '.c-navigation-findaclinic',
        header
      ) as NodeListOf<HTMLAnchorElement>;

      // for the logo, add text as HArmonyCa as mentioned in the document on https://oesjira.abbvienet.com/browse/AAGSB-10
      if (navLogo?.length) {
        navLogo.forEach(link =>
          appendAnchorClickListener(link, eventName, CONSTANTS.brandName)
        );
      }

      // for the main links in the menu
      if (navListAnchors?.length) {
        navListAnchors.forEach(link =>
          appendAnchorClickListener(link, eventName)
        );
      }

      // for the links in the language dropdown
      const langNav = qsAll('.emu-language-nav', header);
      if (langNav?.length) {
        // for the language navigation trigger
        if (langNavTriggers?.length) {
          langNavTriggers.forEach(trigger => {
            // language navigation trigger has an svg icon inside the anchor, which will be considered inside the textContent
            // making sure that the text node is taken carefully. Otherwise falling back to textContent
            let textVal;
            try {
              const textChildNode = trigger.childNodes?.[0];
              if (textChildNode instanceof Text) {
                textVal = textChildNode.textContent;
              }
            } catch (e) {
              textVal = trigger.textContent?.trim();
            }
            appendAnchorClickListener(trigger, eventName, textVal);
          });
        }

        // for the language navigation dropdown
        if (langDropdownAnchors?.length) {
          langDropdownAnchors.forEach(link =>
            appendAnchorClickListener(link, eventName)
          );
        }
      }

      // find a clinic links
      if (findClinicLinks?.length) {
        findClinicLinks.forEach(link =>
          appendAnchorClickListener(link, eventName)
        );
      }
    }
  };

  // adds analytics to the links in the footer
  const addFooterAnalytics = () => {
    const eventName = CONSTANTS.eventNames.footerNav;
    const footer = qs('footer.footer');

    if (footer) {
      // for the social links in footer
      const instaAnchor = qs(
        '.footer__social--insta',
        footer
      ) as HTMLAnchorElement;
      const fbAnchor = qs('.footer__social--fb', footer) as HTMLAnchorElement;
      appendAnchorClickListener(instaAnchor, eventName, CONSTANTS.insta);
      appendAnchorClickListener(fbAnchor, eventName, CONSTANTS.fb);

      // for the logo links in footer
      const harmonicaLogo = qs(
        '.c-footer-logos .footer__logo--harmonyca a',
        footer
      ) as HTMLAnchorElement;
      const allerganLogo = qs(
        '.c-footer-logos .footer__logo--allergan a',
        footer
      ) as HTMLAnchorElement;
      appendAnchorClickListener(harmonicaLogo, eventName, CONSTANTS.brandName);
      appendAnchorClickListener(allerganLogo, eventName, CONSTANTS.allergan);

      // for the navigation links in footer
      const footerMainNavLinks = qsAll(
        '.footer__main-nav a',
        footer
      ) as NodeListOf<HTMLAnchorElement>;
      if (footerMainNavLinks?.length) {
        footerMainNavLinks.forEach(link =>
          appendAnchorClickListener(link, eventName)
        );
      }
    }
  };

  // adds analytics to the secondary nav items in the Q&A page
  const addSecondaryNavAnalytics = () => {
    if (document.body.classList.contains('p-qa')) {
      const secondaryNavLinks = qsAll(
        '.c-topicnavigation__container a'
      ) as NodeListOf<HTMLAnchorElement>;
      if (secondaryNavLinks?.length) {
        secondaryNavLinks.forEach(link =>
          appendAnchorClickListener(link, CONSTANTS.eventNames.secondaryNav)
        );
      }
    }
  };

  // initializers
  addBusEvents();
  addHeaderAnalytics();
  addFooterAnalytics();
  addSecondaryNavAnalytics();
};

(() => {
  if (document.readyState === 'complete') {
    initDataLayerEvents();
  } else {
    window.addEventListener('load', initDataLayerEvents);
  }
})();
