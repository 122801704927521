import { Event, ToogleLocationElements, Redirect, BUS_EVENTS } from './locationServices.types'

export const locationServicesFormInit = (element: HTMLElement) => {
  if (!element) {
    return false;
  }

  locationServicesForm(element);
}

const locationServicesForm = (htmlLocationServicesComponent: HTMLElement) => {
  const selectors = {
    dataRedirectionTarget: 'data-redirection-target',
    countryDropdownWapper: 'emu-location-services__country-select-wrapper',
    locationInputWrapper: 'emu-location-services__location-input-wrapper',
    searchButton: 'emu-location-services__search-submit-cta',
    currentLocationButton: 'emu-location-services__current-location-btn',
  }

  let htmlComponent: HTMLElement;
  let locationServicesComponent: HTMLElement;
  let isCountryDropdownWapper: boolean;
  let locationInputWrapper: HTMLElement;
  let searchButton: HTMLButtonElement;
  let currentLocationButton: HTMLButtonElement;
  let countryCode: string;

  const constructor = (): void => {
    locationServicesComponent = htmlComponent.querySelector(`[${selectors.dataRedirectionTarget}]`)!;
    isCountryDropdownWapper = Boolean(htmlComponent.querySelector(`.${selectors.countryDropdownWapper}`));

    if (isCountryDropdownWapper) {
      locationInputWrapper = htmlComponent.querySelector(`.${selectors.locationInputWrapper}`)!
      searchButton = htmlComponent.querySelector(`.${selectors.searchButton}`)!;
      currentLocationButton = htmlComponent.querySelector(`.${selectors.currentLocationButton}`)!;
    }
  }

  const toogleLocationElements: ToogleLocationElements = (force, elements = []) => {
    if (!isCountryDropdownWapper) return;

    elements = (elements.length > 0) ? elements : [searchButton, currentLocationButton, locationInputWrapper];

    elements.map((element) => {
      if (element !== null) {
        element.classList.toggle('hide', force)
      }
    });
  }

  /**
   * @param location - the name of a city (London), place (Kensington Gardens) or coordinates (51.5073509,-0.12775829999998223)
   * @param countryCode - country code like GB, US.
   */
  const redirect: Redirect = (location, countryCode) => {
    // Pathname coming from locationServices component.
    let pathname = locationServicesComponent.getAttribute(`${selectors.dataRedirectionTarget}`);
    const countryCodeQueryParam = (countryCode) ? `&country=${countryCode}` : '';

    if (!location || !pathname) {
      return false;
    };

    window.location.href = `${pathname}.html?location=${location}${countryCodeQueryParam}`;
  }

  const registerEvents = (): void => {
    window.Bus.on(BUS_EVENTS.CHANGE_SELECT, (event: Event) => {
      const location = event.address;
      redirect(location, countryCode);
    });

    window.Bus.on(BUS_EVENTS.CLICK_BUTTON, (event: Event) => {
      const location = event.address;
      redirect(location, countryCode);
    });

    window.Bus.on(BUS_EVENTS.PROMPT_SUSSCESS, (event: Event) => {
      const { latitude, longitude } = event.coords;
      const location = `${latitude},${longitude}`;
      redirect(location, countryCode);
    });

    window.Bus.on(BUS_EVENTS.CHANGE_COUNTRY, ({ country }) => {
      countryCode = country;
      toogleLocationElements(false);
    });
  }

  const init = () => {
    if (htmlLocationServicesComponent) {
      htmlComponent = htmlLocationServicesComponent;
    }

    constructor();
    toogleLocationElements(isCountryDropdownWapper);
    registerEvents();
  }

  init();
}
