import VimeoPlayer from '@vimeo/player';

// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
import './pages/**/*.scss';
// import favicon here.
import './resources/images/favicon.png';

import { beforeAndAfterCarouselInit } from './components/beforeAndAfterCarousel/beforeAndAfterCarousel';
import { clinicsInit } from './pages/clinics/clinics';
import { findClinicFormInit } from './components/findClinicForm/findClinicForm';
import { headerInit } from './components/header/header';
import { searchLocationInit } from './components/searchLocation/searchLocation';
import { teaserInit } from './components/teaser/teaser';
import { topicNavigationInit } from './components/topicNavigation/topicNavigation';

import './analytics';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
    dataLayer: any;
    _googleMapInstances: any; // google map instances
  }
}

// when modal is closed and if it has an embed iframes in it, pausing the video as soon as the modal is closed
const handleModalClose = () => {
  window.Bus.on('emu-modal:close', ({ id }) => {
    const modal = document.querySelector(`[data-id="${id}"]`) as HTMLElement;
    const iframes = modal?.querySelectorAll(
      '.emu-embed iframe'
    ) as NodeListOf<HTMLIFrameElement>;
    if (iframes?.length && VimeoPlayer) {
      iframes.forEach(iframe => {
        const player = new VimeoPlayer(iframe);
        player?.pause?.();
      });
    }
  });
};

const init = () => {
  if (window.Bus) {
    handleModalClose();
  }

  headerInit();
  teaserInit();
  beforeAndAfterCarouselInit();
  topicNavigationInit();
  findClinicFormInit();
  clinicsInit();
  searchLocationInit();
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    init();
  });
} else {
  init();
}
